import { trace } from '@opentelemetry/api';
import { env } from 'env.mjs';
import { Session } from 'next-auth';

import { db } from './db';
import {
  AgeGroupCalculationMethod,
  ageGroupCalculationMethods,
} from 'components/pages/ClubSettings/pageHelpers'; // TODO: BRING THESE 2 TO THIS CONTEXT FILE AND EXPORT FROM HERE IN A SEPARATE PR DUE TO MORE USAGE
import { MAHD_SPORTS_ACADEMY_ID } from 'helpers/constants';
import { prismaCalcey_UNSAFE } from 'server/prisma';

export interface CurrentUser {
  /**
   * The current user object
   */
  user: User;
  /**
   * Club information for the current user.
   */
  club: Club;
}

export interface Club {
  /**
   * The ID of the currently active club
   */
  activeClubId: number;
  /**
   * Active club name
   */
  name: string;
  /**
   * The colour to use for the active club
   */
  color: string;
  /**
   * The logo to use for the active club
   */
  logo: string;
  /**
   * Other possible clubs the user is a member of
   */
  inactiveClubs: InactiveClub[];
  /**
   * Club settings
   */
  clubSettings: ClubSettings;
}

export interface InactiveClub {
  /**
   * The ID of the club
   */
  id: number;
  /**
   * The name of the club
   */
  name: string;
  /**
   * The logo of the club
   */
  logo: string;
}

export interface ClubSettings {
  /**
   * The age group calculation method
   */
  ageGroupCalculationMethod: AgeGroupCalculationMethod;
}

export interface User {
  /**
   * The ID of the current user
   */
  userId: string;
  /**
   * The email of the current user.
   */
  email: string;
  /**
   * The name of the current user
   */
  name: string;
  /**
   * The Role ID of the current user.
   */
  roleId: number;

  /**
   * The current user's AiSCOUTRole
   */
  aiSCOUTRole?: AiSCOUTRole;

  /**
   * The current AiSCOUT Access Token
   */
  accessToken: string;
  /**
   * The current AiSCOUT Refresh Token
   */
  refreshToken: string;
  /**
   * The AiSCOUT Scout ID of the user
   */
  scoutId: number;
  /**
   * The Profile Image of the user
   */
  profileImage: string;
  /**
   * Turk Groups
   */
  groups: UserGroup[];
  /**
   * Preferred unit system (height, weight, etc.)
   */
  preferredUnitSystem: UnitSystem;
}

export type CurrentUserWithRole = {
  club: Club;
  user: Required<User>;
};

// Create a list of types for any lookup of the current user role from the ID
export const AiSCOUTRoleList = [
  '1:AiSCOUTAppointedPerson',
  '2:HeadOfRecruitment',
  '3:HeadScout',
  '4:LocalOrRegionalScout',
  '5:LeadOrHeadCommercial',
  '6:CommercialStaff',
  '7:HeadOfPlayerDevelopmentOrPerformanceOrSportScience',
  '8:SportScienceStaff',
  '9:AiSCOUTStaff',
  '10:Scout',
  '11:Turk',
  '12:AiLabsUser',
  '13:AiLabsExternalStaff',
  '14:MLSNext',
  '15:RecruitmentCentre',
] as const;

// This is the type of the current user role
export type AiSCOUTRole = typeof AiSCOUTRoleList[number];

export const convertRoleIDtoAiSCOUTRoleType = (roleId: number): AiSCOUTRole => {
  const role = AiSCOUTRoleList.find((role) => {
    return role.split(':')[0] === roleId.toString();
  });

  if (role) {
    return role;
  } else {
    throw new Error(`Role ID ${roleId} is not a valid AiSCOUT role`);
  }
};

export const addAiSCOUTRoleToUser = (
  currentUser: CurrentUser
): CurrentUserWithRole => {
  let modifiedCurrentUser = currentUser;
  modifiedCurrentUser.user = {
    ...modifiedCurrentUser.user,
    aiSCOUTRole: convertRoleIDtoAiSCOUTRoleType(currentUser.user.roleId),
  };
  return modifiedCurrentUser as CurrentUserWithRole;
};

// List of turk groups that a user can have
export const UserGroupList = [
  'BenchmarkTurk',
  'SuperTurk',
  'Turk',
  'TurkAdmin',
  'AiLabsCast',
] as const;

export type UserGroup = typeof UserGroupList[number];

export const allUnitSystems = ['metric', 'imperial'] as const;

export type UnitSystem = typeof allUnitSystems[number];

const getCurrentUserFromDatabase = async (id: string) => {
  const controlCentreUser = await db.users.findUnique({
    select: {
      id: true,
      name: true,
      email: true,
      scout_id: true,
      refresh_token: true,
      access_token: true,
      role_id: true,
    },
    where: { id },
  });

  const email = controlCentreUser?.email;

  if (!controlCentreUser || email === null) {
    return null;
  }

  const userId = controlCentreUser.id;
  const scoutId = Number(controlCentreUser.scout_id);
  const roleId = Number(controlCentreUser.role_id);

  const userGroups = await db.user_permission_groups.findMany({
    select: { name: true },
    where: { user_id: userId },
    distinct: ['name'],
    orderBy: { name: 'asc' },
  });

  const groups = userGroups.map<UserGroup>((group) => group.name as UserGroup);

  const scout = await prismaCalcey_UNSAFE.scouts.findFirst({
    select: { Name: true, ProfileImagePath: true },
    where: { Id: scoutId },
  });

  const dbUnitSystem = await db.user_settings.findFirst({
    select: { unit_system: true },
    where: { user_id: userId },
  });

  const user: User = {
    email: email!,
    name: scout?.Name || controlCentreUser.name!,
    userId,
    scoutId,
    accessToken: controlCentreUser.access_token,
    refreshToken: controlCentreUser.refresh_token,
    groups,
    profileImage: scout?.ProfileImagePath || 'user/Player/DefaultProfileImage',
    roleId,
    preferredUnitSystem: dbUnitSystem?.unit_system
      ? (dbUnitSystem.unit_system as UnitSystem)
      : allUnitSystems[0],
  };

  const allUserClubs = await db.user_pro_club_access.findMany({
    select: { pro_club_id: true, is_active: true },
    where: { user_id: userId },
    orderBy: { is_active: 'asc' },
  });

  const proClubs = await prismaCalcey_UNSAFE.proClubs.findMany({
    select: {
      Id: true,
      Name: true,
      LogoRelativePath: true,
      ThemeColorCode: true,
    },
    where: {
      Id: { in: allUserClubs.map((club) => Number(club.pro_club_id) || 0) },
    },
    orderBy: { Name: 'asc' },
  });

  const activeClubId = Number(
    allUserClubs.filter((club) => club.is_active)[0]?.pro_club_id || 0
  );

  const activeClub = proClubs.filter(
    (club) => club.Id === BigInt(activeClubId)
  )[0];

  const clubSettings = await db.control_centre_pro_club_settings.findFirst({
    where: { pro_club_id: activeClubId },
  });

  const formattedClubSettings = {
    ageGroupCalculationMethod:
      clubSettings?.age_group_calculation_method ??
      ageGroupCalculationMethods[0], // Default to UK School Year
  };

  const club: Club = {
    activeClubId,
    name: activeClub?.Name,
    logo: activeClub?.LogoRelativePath,
    color:
      activeClubId === MAHD_SPORTS_ACADEMY_ID
        ? '#B45FF0'
        : activeClub?.ThemeColorCode,
    inactiveClubs: proClubs
      .filter((club) => club.Id !== BigInt(activeClubId))
      .map((proClub) => ({
        id: Number(proClub.Id),
        name: proClub.Name,
        logo: proClub.LogoRelativePath,
      })),
    clubSettings: formattedClubSettings,
  };

  const currentUser: CurrentUser = {
    user,
    club,
  };

  return addAiSCOUTRoleToUser(currentUser);
};

/**
 * 1. CONTEXT
 *
 * This section defines the "contexts" that are available in the backend API.
 *
 * These allow you to access things when processing a request, like the database, the session, etc.
 *
 * This helper generates the "internals" for a tRPC context. The API handler and RSC clients each
 * wrap this and provides the required inputs to create the context from their
 * version the request etc .
 *
 * @see https://trpc.io/docs/server/context
 */
export const createContextInner = async ({
  headers,
  session,
}: {
  headers: Headers;
  session: Session | null;
}) => {
  return trace
    .getTracer('trpc')
    .startActiveSpan('trpc:createContext', async (span) => {
      const returnContext = await (async () => {
        if (session) {
          try {
            const currentUser = await getCurrentUserFromDatabase(
              session.user.id
            );
            return {
              headers,
              currentUser,
              db,
            };
          } catch {
            // console.log('Context found a session cookie but it was invalid');

            return {
              headers,
              currentUser: null,
              db,
            };
          }
        } else {
          // console.log('Context found no session cookie');
          return {
            headers,
            currentUser: null,
            db,
          };
        }
      })();

      span.setAttributes({
        environment: env.NEXT_PUBLIC_ENVIRONMENT,
        userId: returnContext.currentUser?.user.userId || 0,
      });
      span.end();

      return returnContext;
    });
};

export type Context = Awaited<ReturnType<typeof createContextInner>>;
